<template>
	<button type="button" class="rounded-full h-6 w-6 flex items-center justify-center hover:bg-slate-900" v-if="theme === 'light'" @click="switchToDark">
		<i class="fa-solid fa-sun"></i>
	</button>
	<button type="button" class="rounded-full h-6 w-6 flex items-center justify-center hover:bg-slate-900" v-else @click="switchToLight">
		<i class="fa-solid fa-moon"></i>
	</button>
</template>
<script>
import {ref, defineComponent} from 'vue'

export default defineComponent({
	name: 'ThemeChooser',
	setup() {
		const theme = ref(localStorage.theme)
		return {
			theme,
		}
	},
	methods: {
		switchToLight(){
			this.theme = localStorage.theme = 'light'
			document.documentElement.classList.remove('dark')
		},
		switchToDark(){
			this.theme = localStorage.theme = 'dark'
			document.documentElement.classList.add('dark')
		},
		prefersOS(){
			if(window.matchMedia('(prefers-color-scheme: dark)').matches){
				this.theme = 'dark'
			} else {
				this.theme = 'light'
			}
			localStorage.removeItem('theme')
		},
	},
})
</script>